'use client';

import { useParams, usePathname } from 'next/navigation';
import { useEffect } from 'react';

const setPageTitle = (title: string) => {
  if (window.document) {
    window.document.title = `Formo - ${title}`;
  }
};

const PageTitle = () => {
  const path = usePathname();
  const { action } = useParams() as { action?: string };

  useEffect(() => {
    if (path === '/' || path.startsWith('/teams')) {
      setPageTitle('Dashboard');
      return;
    }
    if (action === 'edit') {
      setPageTitle('Edit');
      return;
    }
    if (action === 'share') {
      setPageTitle('Share');
      return;
    }
    if (action === 'responses') {
      setPageTitle('Responses');
      return;
    }
    if (action === 'settings') {
      setPageTitle('Settings');
      return;
    }
    // Add more cases as needed
  }, [action, path]);

  return null;
};

export default PageTitle;
