'use client';

import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import '@solana/wallet-adapter-react-ui/styles.css';
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import React, { useState } from 'react';

const network = WalletAdapterNetwork.Mainnet;

export default function SolanaWalletProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [endpoint] = useState(() => clusterApiUrl(network));
  const [wallets] = useState(() => [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter(),
  ]);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}
