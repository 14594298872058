'use client';

import { AuthKitProvider } from '@farcaster/auth-kit';
import React, { FC } from 'react';

const config = {
  rpcUrl: 'https://mainnet.optimism.io',
};

type FarcasterAuthProviderProps = {
  children: React.ReactNode;
};

const FarcasterAuthProvider: FC<FarcasterAuthProviderProps> = ({
  children,
}) => {
  return <AuthKitProvider config={config}>{children}</AuthKitProvider>;
};

export default FarcasterAuthProvider;
