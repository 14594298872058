import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { mainnet } from 'wagmi/chains';

export const projectId = process.env.NEXT_PUBLIC_CONNECT_WALLET_PROJECT_ID!;

const metadata = {
  name: 'Formo',
  description: 'Easily create web3 forms with Formo',
  icons: ['/logo.svg'],
  url:
    process.env.NODE_ENV === 'production'
      ? process.env.NEXT_PUBLIC_PRODUCTION_URL!
      : process.env.NEXT_PUBLIC_DEVELOPMENT_URL!,
};

const chains = [mainnet] as const;
export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  ssr: true,
  // Default storage is localStorage
});
