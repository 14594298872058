'use client';

import { createWeb3Modal } from '@web3modal/wagmi/react';
import React, { FC } from 'react';
import { State, WagmiProvider } from 'wagmi';

import { config, projectId } from './config';

// This inject the config and state into the WagmiProvider
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  themeMode: 'dark',
  themeVariables: {
    '--w3m-border-radius-master': '1px',
    '--w3m-accent': 'rgb(167, 229, 103)',
    '--w3m-font-family': 'var(--font-dm-sans)',
  },
});

type Web3ModalProviderProps = {
  children: React.ReactNode;
  initialState?: State;
};

const Web3ModalProvider: FC<Web3ModalProviderProps> = ({
  children,
  initialState,
}) => {
  return (
    <WagmiProvider config={config} initialState={initialState}>
      {children}
    </WagmiProvider>
  );
};

export default Web3ModalProvider;
